@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "BrontoUltraBlack";
  src: url("./assets/form-fonts/Bronto-Ultrablack.ttf");
}

@font-face {
  font-family: "BourtonScriptBold";
  src: url("./assets/form-fonts/BourtonScriptBold.ttf");
}

@font-face {
  font-family: "Imbus";
  src: url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.ttf")
      format("truetype"),
    url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.otf")
      format("opentype"),
    url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.woff")
      format("woff"),
    url("./assets/form-fonts/imbue-v9-latin-ext_latin-regular.woff2")
      format("woff2");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: static;
}
body.overflow-hidden {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.h2-custom {
  font-size: 2rem;
  line-height: 1.2;
}
.z-100 {
  z-index: 100;
}
/* width */
.modalContainer::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.modalContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #6b7ca0;
  border-radius: 10px;
}

/* Handle */
.modalContainer::-webkit-scrollbar-thumb {
  background: #2a313f;
  border-radius: 10px;
}

/* Handle on hover */
.modalContainer::-webkit-scrollbar-thumb:hover {
  background: #53678f;
}
.main-content {
  width: 100%;
  height: 100%;
  /*background: url('./assets/img/USKRS_pozadina_640x640.png');
  background-position: center center;*/
  background-color: #5fd1cb;
}
.item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #091e47ad;
  display: none;
}
.not-active {
  cursor: auto;
}
.not-active .item-overlay {
  display: block;
  cursor: auto;
}
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0 78 114 / 84%);
  display: flex;
  justify-content: center;
  align-items: center;
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  -webkit-transform: translateZ(0);
  -webkit-font-smoothing: antialiased;
  transform: translateZ(0);
  z-index: 100000;
}

.modalContainer {
  width: 95%;
  max-width: 550px;
  min-width: 360px;
  min-height: 600px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  transform: none !important;
  padding-top: 1rem;
  text-align: center;
  font-weight: 600;
  border: 6px solid #5fd1cb;
  max-height: 95vh;
  overflow-y: auto;
}
.modalContainer.modaThankYou {
  height: 800px;
  min-height: 300px;
}
.modalContainer.modaThankYou .modalForm,
.modalContainer.modaThankYou-bih .modalForm {
  margin-bottom: 0rem;
  margin-top: 0.5rem;
}
.modalContainer.modaThankYou-bih {
  height: 340px;
}

.modalCloseButton {
  width: 38px;
  height: 38px;
  padding: 8px;
  border-radius: 50%;
  position: fixed;
  background: #0074a9;
  cursor: pointer;
  z-index: 100;
  margin-top: -1rem;
}

.modalForm {
  padding: 0;
  position: relative;
  margin: 0 auto;
  color: #000;
  font-size: 1.1rem;
  width: 80%;
  border-radius: 10px;
  margin-bottom: 5rem;
}
.modalForm.contentForm {
  padding: 5rem 1.8rem;
  margin-top: 0;
  width: 60%;
}

.modalPrizeImage {
  position: absolute;
  width: 280px;
  z-index: 1;
  height: 280px;
  border-radius: 50%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ffffff;
  top: -140px;
}
.modalPrizeTitles {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  border-radius: 50%;
  background-color: #091e47ed;
  border: 4px solid;
  flex-direction: column;
  visibility: hidden;
  text-align: left;
}
.modalPrizeTitles ol {
  list-style: auto;
  padding-left: 45px;
  font-size: 14px;
  padding-right: 22px;
  font-weight: 500;
}
.modalPrizeTitles li {
  line-height: 1.2;
  margin-bottom: 10px;
}
.modalPrizeTitles li:last-child {
  margin-bottom: 0;
}
.modalPrizeTitles a.disabled-link {
  pointer-events: none;
}
.modalPrizeTitles strong {
  font-weight: 900;
}
.modalPrizeTitles a:hover {
  text-decoration: underline;
}
.modalPrizeImage:hover .modalPrizeTitles {
  visibility: visible;
}
.starTail {
  position: absolute;
  left: -14%;
  top: -240px;
}

.starHead {
  position: absolute;
  top: -298px;
  right: -40px;
  z-index: 2;
}

.modalSubmit {
  position: absolute;
  bottom: -5rem;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  font-size: 20px;
  color: #fff;
  padding: 0.6rem 2rem;
  background-color: #da0155 !important;
  font-family: "BrontoUltraBlack";
  border-bottom: 4px solid #ff8d94;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #ff8d94;
}
.no-newsletter .modalSubmit {
  bottom: -4rem;
}

.basic-button {
  text-transform: uppercase;
  font-size: 20px;
  color: #fff;
  padding: 0.5rem 2rem;
  background-color: #da0155 !important;
  font-family: "BrontoUltraBlack";
  border-bottom: 4px solid #ff8d94;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #ff8d94;
  margin-top: 15px;
  display: inline-block;
}
.image-button {
  width: 277px;
  height: 81px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}
.image-button.button-dobitnici {
  background-image: url("./assets/img/BUTTON_DOBITNICI.png");
}
.image-button.button-dobitniki {
  background-image: url("./assets/img/SLO_BUTTON_DOBITNIKI.png");
}
.image-button.button-pravila {
  background-image: url("./assets/img/BUTTON_PRAVILA.png");
}

.modalSubmit.loading {
  pointer-events: none;
  opacity: 0.7;
}

.fileUpload {
  text-transform: uppercase;
  font-size: 20px;
  color: #fff;
  padding: 0.5rem 2rem;
  background-color: #f4b702 !important;
  font-family: "BrontoUltraBlack";
  border-bottom: 4px solid #efa900;
  border-radius: 5px;
  text-shadow: 0px 1px 3px #c36c07;
}

.formLabel {
  font-family: "BrontoUltraBlack";
  font-size: 18px;
  font-weight: bolder;
  line-height: 1;
}

.modalForm input,
.modalForm select,
.sib-form input {
  height: 42px;
  padding: 5px 10px;
  font-size: 14px;
  color: #000;
}
.modalForm input[type="checkbox"] {
  height: 25px;
  width: 25px !important;
  min-width: 25px;
  accent-color: #5fd1cb;
}

.modalForm select {
  width: 100%;
  height: 32px;
  color: #000000;
}

.modalForm input:focus {
  outline-style: solid;
  outline-color: #d5b971;
}

.modalForm textarea:focus {
  outline-style: solid;
  outline-color: #d5b971;
}

.modalForm textarea {
  height: 60px;
  color: #000;
  padding: 5px 10px;
  font-size: 14px;
}

.modalForm input:not([type="checkbox"]),
.sib-form input:not([type="checkbox"]),
select,
textarea {
  width: 100%;
  border-radius: 5px;
  border: 3px solid #5fd1cb;
}

.mainContent {
  padding: 3rem 1rem 1rem 1rem;
}

.mainContent h1 {
  font-family: "Imbus";
}

.mainContent h2,
p {
  font-family: "Roboto Condensed";
}

.mainContent p {
  font-size: 1.3rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.mainContentStar {
  width: 100%;
  min-width: 300px;
  max-width: 1280px;
  height: intrinsic;
}

.mainContentPrizes {
  width: 100%;
  min-width: 300px;
  max-width: 870px;
}

.formError {
  width: 100%;
  background: #f80021d2;
  margin-top: 0.5rem;
  font-family: "Roboto Condensed";
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 15px;
  border-radius: 5px;
  padding: 8px;
  line-height: 1.2;
}
.fileUpload {
  position: relative;
}
.fileUpload input {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  height: 30px;
  cursor: pointer;
}
.subtitle {
  letter-spacing: -1px;
  font-size: 0.9rem;
  color: #3f3f3f !important;
}

/* Newsletter */
.sib-form {
  padding: 0;
  position: relative;
  margin: 0 auto;
  color: #000;
  font-size: 1.1rem;
  width: 80%;
  border-radius: 10px;
  margin-bottom: 2rem;
}
/* Game */
.product-floating-element {
  width: 600px;
}
.product-floating-element h3 {
  font-family: "Imbus";
}
.product-floating-element__button {
  background-color: #da0155;
  font-family: "Roboto Condensed";
}
.psv-marker--normal {
  cursor: pointer;
}
.psv-marker.marker-clicked {
  opacity: 0.5 !important;
}
.product-floating-element__image {
  width: 200px;
  min-width: 200px;
  max-width: 200px;
  position: relative;
}
.product-floating-element__image img {
  max-height: 200px;
  object-fit: contain;
  height: auto;
}
.style-2 .product-floating-element__image {
  top: 0;
  height: 100%;
}
.style-2 .product-floating-element__image img {
  max-height: 100%;
  object-fit: cover;
  height: 100%;
}
.style-2 .product-floating-element__button {
  background-color: transparent;
  font-family: "Roboto Condensed";
}
.style-2.product-floating-element {
  width: calc(50% - 0.625rem);
}
.style-2 .product-floating-element__button {
  color: #8c7256;
  font-size: 1rem;
  position: relative;
  padding-left: 14px;
  display: inline-block;
}
.style-2 .product-floating-element__button:before {
  content: ">";
  position: absolute;
  left: 0;
  top: 0;
}
.bodovi-box {
  background-color: #f1b401d4;
}
.game-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #1a7470;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
}
.game-overlay.game-overlay--visible {
  animation: 2.3s forwards overlayFadeIn;
}
.psv-container {
  background: #a9ebf6 !important;
}
@keyframes overlayFadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  30% {
    opacity: 0.98;
    visibility: visible;
  }
  80% {
    opacity: 0.98;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.points-text {
  text-shadow: 0px 1px 3px #c36c07;
  font-family: "Roboto Condensed";
}
.custom-tooltip .psv-tooltip-content {
  color: rgb(255, 255, 255);
  font: 18px sans-serif;
  text-shadow: 0px 1px 2px #c36c07;
  font-family: "Roboto Condensed";
}
.custom-tooltip .psv-tooltip-content h4 {
  font: 21px sans-serif;
  margin-bottom: 0 !important;
}
.custom-tooltip.psv-tooltip {
  background-color: #f1b401d4;
  box-shadow: 0 10px 8px rgb(0 0 0 / 0.1) !important;
  text-align: center;
}
.custom-tooltip.psv-tooltip--top-left .psv-tooltip-arrow,
.custom-tooltip.psv-tooltip--top-center .psv-tooltip-arrow,
.custom-tooltip.psv-tooltip--top-right .psv-tooltip-arrow {
  border-top-color: #f1b401d4;
}
@media only screen and (max-width: 680px) {
  .mainContent {
    padding: 2rem 0rem 0rem 0rem;
  }

  .mainContent p {
    font-size: 1.1rem;
  }

  .mainContent p {
    margin-bottom: 0.8rem;
  }
  .h2-custom {
    font-size: 1.5rem;
    line-height: 1.1;
  }
}
@media only screen and (max-width: 850px) {
  .modalForm.contentForm {
    width: 80%;
  }
  .style-2.product-floating-element {
    width: 100%;
  }
  .content-hero-bih {
    min-height: 550px !important;
    height: 750px !important;
  }
}
@media only screen and (max-width: 768px) {
  .content-hero {
    min-height: 550px !important;
    height: 550px !important;
  }
  .content-hero-bih {
    min-height: 550px !important;
    height: 720px !important;
  }
  .product-wrapper {
    right: 1rem !important;
    top: 5rem !important;
    left: 1rem !important;
  }
  .product-floating-element {
    width: 100%;
    padding: 1rem 1.7rem 1rem !important;
  }
  .product-floating-element__image {
    width: 100px;
    min-width: 100px;
    max-width: 100px;
  }
  .modalContainer iframe {
    height: 400px !important;
  }
  .modalContainer.modaThankYou {
    height: 760px;
  }
}
@media only screen and (max-width: 767px) {
  .style-2.product-floating-element {
    flex-direction: column;
  }
  .style-2 .product-floating-element__image {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: 200px !important;
  }
  .modal-title {
    font-size: 2rem !important;
  }
  .modalForm h3 {
    font-size: 1.2rem !important;
  }
  .modalContainer.modaThankYou {
    height: 760px;
    min-height: 460px;
    margin-top: 0;
    padding: 0 0rem 1rem !important 0;
    align-items: center;
  }
  .modalContainer.modaThankYou-bih {
    height: 290px;
    min-height: 290px;
    margin-top: 0;
    padding: 0 0rem 1rem !important 0;
    align-items: center;
  }
  .modalContainer iframe {
    height: 450px !important;
  }
  .modaThankYou .modalForm {
    padding-bottom: 0 !important;
  }
}

@media only screen and (max-width: 500px) {
  .content-hero {
    min-height: 300px !important;
    height: 300px !important;
  }
  .content-hero-bih {
    min-height: 340px !important;
    height: 340px !important;
  }
}
@media only screen and (max-width: 370px) {
  .content-hero {
    min-height: 250px !important;
    height: 260px !important;
  }
}

@media only screen and (max-width: 510px) {
  .modalForm {
    width: 100%;
    padding: 0 1rem 1.4rem 1rem;
  }

  .modalPrizeImage {
    width: 230px;
    height: 230px;
    top: -115px;
  }
  .modalForm.contentForm {
    width: 97%;
  }
  .modalContainer.modaThankYou .modalForm,
  .modalContainer.modaThankYou-bih .modalForm {
    width: 100%;
  }
}

@media only screen and (max-width: 460px) {
  .starTail {
    position: absolute;
    left: -12%;
    top: -205px;
    width: 90%;
  }

  .starHead {
    position: absolute;
    top: -260px;
    right: -15px;
    z-index: 2;
    width: 64%;
  }

  .modalContainer {
    min-width: 310px;
  }
}

@media only screen and (max-width: 350px) {
  .starHead {
    top: -235px;
  }

  .starTail {
    position: absolute;
    left: -12%;
    top: -185px;
  }
  .modalContainer iframe {
    height: 500px !important;
  }
  .modalContainer.modaThankYou-bih {
    height: 360px;
    min-height: 360px;
  }
  .content-hero-bih {
    min-height: 240px !important;
    height: 300px !important;
  }
}
